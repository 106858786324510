<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="1800px"
    max-height="1000px"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >Gerber Upload</span
        >
        <v-spacer />
        <AdminModeToggle v-if="isAdmin" @updateAdminMode="updateAdminMode" />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-sheet :color="dragIn ? 'alert' : 'primary'" dark outlined shaped>
              <div
                v-if="!gerber_files && gerberFiles.length === 0"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
                class="pa-10"
              >
                <input
                  type="file"
                  style="display: none"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  @change="onConvert"
                  ref="file"
                  accept=".zip"
                  data-cy="rfq-gerber-upload-file-input"
                />
                <label for="assetsFieldHandle" class="fieldLink">
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <v-icon x-large>
                        mdi-cloud-upload
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h1 class="mt-2 pa-1 font-weight-bold">
                        Drag and drop your zipped Gerber files
                      </h1>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <p>
                        Follow
                        <a
                          class="white--text ml-1"
                          href="https://fixturfab.com/articles/uploading-gerbers-to-fixturfab/"
                          target="_blank"
                          >this guide<v-icon small class="mx-1"
                            >mdi-open-in-new</v-icon
                          ></a
                        >
                        for instructions on how to upload Gerbers compatible
                        with FixturFab.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h4>
                        Upload gerber files for a single unit. Contact
                        <a
                          class="white--text"
                          href="mailto:support@fixturfab.com"
                          >support@fixturfab.com</a
                        >
                        if you are interested in a panel fixture.
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h3 class="my-3">
                        Or you may
                        <span class="font-weight-light">click here</span> to
                        upload your zipped Gerber files
                      </h3>
                    </v-col>
                  </v-row>
                </label>
              </div>
              <div v-else class="pa-10">
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-icon x-large>
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <h1 class="mt-2 pa-1 font-weight-bold">
                      {{
                        gerber_files
                          ? gerber_files.name
                          : "Currently saved Gerber Files"
                      }}
                    </h1>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <div class="pa-1">
                      Saving the gerber files FixturFab will use to design your
                      fixture.
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-btn
                      text
                      @click="initializeLists"
                      data-cy="rfq-gerber-upload-remove-file"
                      >Remove all files</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-sheet
          outlined
          class="my-2"
          v-if="invalidGerbers && invalidGerbers.length > 0 && !isLoading"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" class="d-flex justify-center warning--text my-2">
                <span>Timed out while parsing the following Gerber files.</span>
              </v-col>
            </v-row>
            <base-scroll-file-list :fileList="invalidGerbers" />
          </v-container>
        </v-sheet>
        <transition name="slide">
          <div
            v-show="(gerberFiles.length > 0 || !gerber_files) && !isLoading"
            class="mx-16"
          >
            <v-form lazy-validation ref="form">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-row dense class="pa-0 mt-10 mx-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1">Top Copper Layer</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="topCopperContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ topCopperContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formTopCopper"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              class="mb-1"
                              :disabled="topCopperContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formTopCopper,
                                  side: 'top',
                                  type: 'copper',
                                  gerberFormName: 'formTopCopper',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1">Top Soldermask</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="topSoldermaskContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ topSoldermaskContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formTopSoldermask"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="topSoldermaskContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formTopSoldermask,
                                  side: 'top',
                                  type: 'soldermask',
                                  gerberFormName: 'formTopSoldermask',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1">Top Overlay/Silkscreen</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="topSilkscreenContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ topSilkscreenContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formTopSilkscreen"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="topSilkscreenContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formTopSilkscreen,
                                  side: 'top',
                                  type: 'silkscreen',
                                  gerberFormName: 'formTopSilkscreen',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0 mt-10">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1">Bottom Copper Layer</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="bottomCopperContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ bottomCopperContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formBottomCopper"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="bottomCopperContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formBottomCopper,
                                  side: 'bottom',
                                  type: 'copper',
                                  gerberFormName: 'formBottomCopper',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1">Bottom Soldermask</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="bottomSoldermaskContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ bottomSoldermaskContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formBottomSoldermask"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="bottomSoldermaskContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formBottomSoldermask,
                                  side: 'bottom',
                                  type: 'soldermask',
                                  gerberFormName: 'formBottomSoldermask',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span class="subtitle-1"
                            >Bottom Overlay/Silkscreen</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="bottomSilkscreenContainer"
                            ><v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ bottomSilkscreenContainer.file }}</v-alert
                            ></span
                          >
                          <span v-else>
                            <v-file-input
                              v-model="formBottomSilkscreen"
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="bottomSilkscreenContainer"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateRender({
                                  gerberFile: formBottomSilkscreen,
                                  side: 'bottom',
                                  type: 'silkscreen',
                                  gerberFormName: 'formBottomSilkscreen',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row dense class="pa-0 mt-9 mx-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span
                            class="subtitle-1"
                            v-show="allMechanicalContainer.length == 0"
                            >Mechanical Outliine</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="allMechanicalContainer.length > 0"></span>
                          <span v-else>
                            <v-file-input
                              v-model="formAllMechanical"
                              multiple
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="allMechanicalContainer.length > 0"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateMechanical({
                                  gerberFile: formAllMechanical,
                                  side: 'all',
                                  type: 'outline',
                                  gerberFormName: 'formAllMechanical',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    class="pa-0 ma-0"
                    v-for="(mechanical, index) in allMechanicalContainer.slice(
                      0,
                      3
                    )"
                    :key="`mech${index}`"
                  >
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span v-show="index == 0" class="subtitle-1"
                            >Mechanical Outliine</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="allMechanicalContainer.length > 0">
                            <v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ mechanical.filename.split("?")[0] }}</v-alert
                            ></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12" md="6" offset-md="4">
                      <span v-if="allMechanicalContainer.length > 3">
                        <BaseListMenuPopup
                          :items="allMechanicalContainer"
                          :activatorText="
                            `View all ${allMechanicalContainer.length} Mechanical Layer files`
                          "
                      /></span>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 mt-5 mx-0">
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span
                            class="subtitle-1"
                            v-show="allDrillContainer.length == 0"
                            >Drill File / NC Drill</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="allDrillContainer.length > 0"></span>
                          <span v-else>
                            <v-file-input
                              v-model="formAllDrill"
                              multiple
                              outlined
                              dense
                              hide-details
                              prepend-icon=""
                              placeholder="Upload file"
                              :disabled="allDrillContainer.length > 0"
                              :rules="
                                hybridRules({
                                  required: true,
                                })
                              "
                              @change="
                                updateMechanical({
                                  gerberFile: formAllDrill,
                                  side: 'all',
                                  type: 'drill',
                                  gerberFormName: 'formAllDrill',
                                })
                              "
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    class="pa-0 ma-0"
                    v-for="(drill, index) in allDrillContainer.slice(0, 3)"
                    :key="`drill${index}`"
                  >
                    <v-col cols="12">
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex align-center justify-center"
                        >
                          <span v-show="index == 0" class="subtitle-1">
                            Drill File / NC Drill</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <span v-if="allDrillContainer.length > 0">
                            <v-alert
                              outlined
                              dense
                              v-ripple
                              color="primary"
                              class="defaultLink caption text-center"
                              max-width="390"
                              >{{ drill.filename.split("?")[0] }}</v-alert
                            ></span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="pa-0 ma-0">
                    <v-col cols="12" md="6" offset-md="4">
                      <span v-if="allDrillContainer.length > 3">
                        <BaseListMenuPopup
                          :items="allDrillContainer"
                          :activatorText="
                            `View all ${allDrillContainer.length} Drill Layer files`
                          "
                      /></span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <v-row dense>
              <v-col cols="12">
                <GerberLayers
                  key="2"
                  v-if="zipGerberFiles.length > 0 && !isLoading"
                  ref="gerber_layers2"
                  :topCopperContainer="topCopperContainer"
                  :topSoldermaskContainer="topSoldermaskContainer"
                  :topSilkscreenContainer="topSilkscreenContainer"
                  :bottomCopperContainer="bottomCopperContainer"
                  :bottomSoldermaskContainer="bottomSoldermaskContainer"
                  :bottomSilkscreenContainer="bottomSilkscreenContainer"
                  :allMechanicalContainer="allMechanicalContainer"
                  :allDrillContainer="allDrillContainer"
                  :testPoints="testPoints"
                />
              </v-col>
            </v-row>
          </div>
        </transition>
        <v-container
          v-if="gerberFiles.length === 0 && gerber_files && !isLoading"
        >
          <v-row dense>
            <v-col cols="12" class="d-flex justify-center">
              <span class="subtitle-1 font-weight-bold warning--text"
                >No valid Gerber files detected</span
              >
            </v-col>
          </v-row>
          <v-row dense v-if="dirFolders && dirFolders.length > 1">
            <v-col cols="12" class="d-flex justify-center">
              <span class="subtitle-1 primary--text"
                >Upload a zip file of a single directory containing the
                Gerbers.</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions class="ma-2">
          <v-row
            v-if="
              gerberFiles.length > 0 &&
                !isLoading &&
                (gerber_files || zipFileContent)
            "
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="warning"
                class="mx-auto"
                @click="onCancel"
                data-cy="rfq-gerber-upload-cancel-button"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="mx-auto"
                @click="onSave"
                data-cy="rfq-gerber-upload-save-button"
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="d-flex justify-center">
              <v-btn
                color="warning"
                @click="onClose"
                data-cy="rfq-gerber-upload-close-button"
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import GerberUpload from "@/mixins/GerberUpload";
import { mapActions } from "vuex";
import GerberLayer from "@/mixins/GerberLayer";
import { getFileBlob } from "@/helpers/basehelper.js";
export default {
  name: "GerberParserModal",
  mixins: [GerberLayer, GerberUpload],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    gerbers: {
      type: Array,
      default: () => [],
    },
    gerberZipFile: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    dutDetails: {
      type: Object,
      default: null,
    },
  },
  components: {
    GerberLayers: () => import("./GerberLayers.vue"),
    AdminModeToggle: () => import("./AdminModeToggle.vue"),
  },
  computed: {
    testPoints() {
      return Object.keys(this.dutDetails.dut_file_data).length > 0
        ? JSON.parse(this.dutDetails.dut_file_data).test_points
        : [];
    },
  },
  methods: {
    getFileBlob,
    ...mapActions({
      toggleLoading: "ui/loading",
    }),
    initializeLists() {
      this.gerber_files = this.artFiles = this.dirFolders = this.otherFiles = null;
      this.invalidGerbers.splice(0);
      this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
      this.formTopCopper = this.formTopSoldermask = this.formTopSilkscreen = this.formBottomCopper = this.formBottomSoldermask = this.formBottomSilkscreen = this.formAllMechanical = this.formAllDrill = null;
      this.$refs.form.reset();
    },
    onClose() {
      this.initializeLists();
      this.$emit("closeModal");
    },
    onCancel() {
      if (this.isLoading) return;
      this.initializeLists();
      this.$emit("closeModal");
    },
    onSave() {
      if (
        (!this.zipGerberFiles || this.zipGerberFiles.length === 0) &&
        this.gerbers.length === 0
      )
        return;

      if (
        (!this.topCopperContainer ||
          !this.topSoldermaskContainer ||
          !this.topSilkscreenContainer ||
          !this.bottomCopperContainer ||
          !this.bottomSoldermaskContainer ||
          !this.bottomSilkscreenContainer ||
          this.allMechanicalContainer.length == 0 ||
          this.allDrillContainer.length == 0) &&
        (!this.isAdmin || this.disableAdminMode)
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }
      if (this.gerber_files || this.zipFileContent) {
        const actionPayload = this.zipGerberFiles.reduce((mapped, element) => {
          mapped.push(...element.data);
          return mapped;
        }, []);
        console.log("SAVED GERBERS", actionPayload);
        this.$emit("saveGerbers", {
          actionPayload,
          zipped_file: this.gerber_files,
          withExistingGerbers: this.withExistingGerbers,
          isBackwardCompatibility: !!this.zipFileContent,
        });
      } else {
        this.$emit("closeModal");
      }
      this.initializeLists();
    },
    initLayers() {
      this.$refs["gerber_layers2"] &&
        this.$refs["gerber_layers2"].initializeViewBox();
    },
    initLayersWithResponse(payload) {
      this.$refs["gerber_layers2"] &&
        this.$refs["gerber_layers2"].initLayers(payload);
    },
  },
  async mounted() {
    this.dragIn = false;
    this.isLoading = false;
    this.initializeLists();
    console.log(this.savedGerbers);
    if (this.gerberZipFile && this.savedGerbers.length == 0) {
      //Backward compatibity handler
      this.zipFileContent = await this.getFileBlob(this.gerberZipFile);
      console.log("ZIP File Content", this.gerberZipFile, this.zipFileContent);
      await this.zipFileParser(this.zipFileContent);
    } else {
      this.withExistingGerbers = this.savedGerbers.length > 0;
      await this.loadSavedGerbers();
    }
  },
};
</script>
<style scoped>
.verticalScroll {
  overflow-y: scroll;
}
.admin-label /deep/ label {
  color: #950952 !important;
}
</style>
