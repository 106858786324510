export default {
  computed: {
    topCopperContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "1 - Top Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "copper");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    topSoldermaskContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "1 - Top Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "soldermask");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    topSilkscreenContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "1 - Top Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "silkscreen");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    bottomCopperContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "2 - Bottom Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "copper");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    bottomSoldermaskContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "2 - Bottom Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "soldermask");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    bottomSilkscreenContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "2 - Bottom Layer"
        );
        let topCopper = null;
        if (top && top.data.length > 0) {
          topCopper = top.data.find((element) => element.type == "silkscreen");
        }
        return topCopper
          ? { file: topCopper.filename.split("?")[0], svg: topCopper.svg }
          : null;
      } else {
        return null;
      }
    },
    allMechanicalContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "3 - Mechanical Layers"
        );
        let topCopper = [];
        if (top && top.data.length > 0) {
          topCopper = top.data;
        }
        return topCopper;
      } else {
        return [];
      }
    },
    allDrillContainer() {
      if (this.zipGerberFiles.length > 0) {
        const top = this.zipGerberFiles.find(
          (element) => element.side == "4 - Drill Layers"
        );
        let topCopper = [];
        if (top && top.data.length > 0) {
          topCopper = top.data;
        }
        return topCopper;
      } else {
        return [];
      }
    },
  },
  methods: {
    async loadSavedGerbers() {
      if (!this.savedGerbers) return;
      this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
      this.isLoading = true;

      for (let i = 0; i < this.savedGerbers.length; i++) {
        const actionPayload = {
          valid: true,
          payload: {
            side:
              this.savedGerbers[i].side == "top"
                ? "1 - Top Layer"
                : this.savedGerbers[i].side == "bottom"
                ? "2 - Bottom Layer"
                : this.savedGerbers[i].layer == "outline"
                ? "3 - Mechanical Layers"
                : "4 - Drill Layers",
            data: [
              {
                pk: this.savedGerbers[i].id,
                filename: this.savedGerbers[i].file.split("?")[0].split("/").pop(),
                gerber: null,
                svg: this.savedGerbers[i].svg_data,
                type: this.savedGerbers[i].layer,
                side: this.savedGerbers[i].side,
              },
            ],
          },
        };
        console.log(actionPayload);
        this.$store.dispatch("dutconfigs/saveZipGerberFiles", actionPayload);
      }
      this.isLoading = false;
      this.initLayers();
    },
  },
};
